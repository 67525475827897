.inteach-settings {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    border-bottom: 1px solid var(--lightgrey);
    cursor: pointer;
    color: var(--darkgrey);
  }

  &__lang-select {
    background: none;
    border: none;
    color: var(--darkgrey);
    font-size: medium;
    font-family: Montserrat, sans-serif;
  }
}