.str-chat__channel-list-messenger {
    background: #fff !important;
}

.raf-card.raf-card--with-image {
  text-decoration: none;
}

.raf-card.raf-card--with-image:hover {
  text-decoration: underline;
  text-decoration-color: #333;
}

.raf-card__image img {
  max-width: 100%;
}

.raf-card__content {
  color: #333;
  text-decoration: none;
}
.inteach-chat-component__chat {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.inteach-chat-component__content {
  width: 100%;
  display: flex;
}

.inteach-chat__modal {
  background: #fff;
  z-index: 999;
  position: absolute;
  right: 5%;
  bottom: 0;
  padding-bottom: 10px;
  overflow: hidden;
  height: 60%;
  width: 25rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 5%;
    background-color: var(--main-color);
    color: #fff;
    position: relative;
    z-index: 9999;

    &__name {
      font-weight: bold;
    }

    &__button {
      background-color: transparent;
      border: none;
      cursor: pointer;

      svg {
        fill: #fff;
      }
    }
  }
}

.inteach-chat-component {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__chat {
    overflow: scroll;
    max-height: 40vh;
  }

  &__list {
    margin: 1.3rem 0;
    background-color: white !important;
  }
}

@media (min-width: 2400px) {
  .inteach-chat__modal {
    width: 16vw;
  }
}

@media (max-width: 577px) {
  .inteach-chat__modal {
    height: 100%;
    width: 100%;
    right: 0%;
    border: none;
    border-radius: 0px;
    position: fixed;
  }

  .inteach-chat-component {
    &__content {
      display: flex;
      flex-direction: column;
      margin: 4vh 5vw 4vh 5vw;
    }

    &__chat {
      margin-top: 2vh;
      overflow: scroll;
      max-height: 100vh;
    }

    &__list {
      max-height: 100vh;
      margin: 1.3rem 0;
      background-color: white !important;
    }
  }
}

.inteach-channel {
  &__preview {
    width: 100%;
    flex-direction: row;
    justify-content: start;
    display: flex;
    align-items: center;
    background-color: Transparent;
    border: none;
    cursor: pointer;
    padding: 5px 0;
  }

  &__avatar {
    box-shadow: 5px 5px 20px 1px rgba(0, 0, 0, 0.1);
  }

  &__coach {
    flex-direction: column;
    display: flex;
    margin-left: 1rem;
    text-align: left;
  }

  &__name {
    font-weight: bold;
    line-height: 20px;
    font-size: 15px;
  }

  &__job {
    color: var(--principal-text);
    line-height: 18px;
    font-size: 13px;
  }

  &__badge {
    position: relative;

    &__container {
      position: absolute;
      background-color: white;
      right: -5px;
      top: -5px;
      border: 2px solid var(--primary);
      border-radius: 20px;
      height: 20px;
      width: 20px;
      z-index: 1;
      align-items: center;
      justify-content: center;
    }

    &__text {
      color: var(--primary);
      font-weight: bold;
    }
  }
}

.inteach-post-comments-send {
  margin-bottom: 50px;
}

.post__tag {
  display: none !important;
}