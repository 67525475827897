.inteach-account {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__name {
    //margin-top: 2rem;
    padding: 1rem 0;
    border-bottom: 1px solid var(--lightgrey);
  }

  &__email {
    padding: 1rem 0;
  }

  &__stats {
    margin-top: 2rem;
    display: flex;
    border-top: 1px solid var(--lightgrey);
    border-bottom: 1px solid var(--lightgrey);
    padding: 1rem 0;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: var(--grey);
      max-width: 120px;

      span {
        font-weight: 500;
        font-size: 1.2rem;
        color: var(--darkgrey);
        margin: 0.5rem 0;
      }
    }
  }

  &--error {
    color: var(--grey);
    text-align: center;
  }
}
