
.inteach-avatar {
  height: 30px;
  width: 30px;
  border-radius: 40px;
  background-color: lightgray;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  display: flex;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  span {
    font-weight: bold;
    text-transform: uppercase;
  }
}