@import "../../../assets/colors.scss";

.inteach-leftbar {
  flex: 1;
}

.inteach-leftbar__content {
  box-sizing: border-box;
  padding: 2rem 3rem;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  height: 100%;
}

.inteach-leftbar__picture {
  border-radius: 300px;
  height: 90px;
}

.inteach-leftbar__title {
  margin-top: 20px;
  margin-bottom: 10px;
  color: $blue-600;
}

.inteach-leftbar__points {
  color: $gold;
  font-size: 2rem;
  font-weight: bold;
  align-items: center;
  display: flex;

  svg {
    margin-left: 5px;
    fill: $gold;
  }
}

.inteach-leftbar__nav {
  margin-top: 4rem;

  ul {
    list-style: none;
    padding-inline-start: 0;

    li {
      margin: 18px 0;
    }
  }

  a {
    color: $gray-600;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: center;

    svg {
      fill: $gray-600;
      margin-right: 8px;
    }
  }
}

.inteach-leftbar__link {
  a.active {
    color: var(--main-color);
  }

  a.active svg {
    fill: var(--main-color);
  }
}

@media (max-width: 840px) {
  .inteach-leftbar {
    display: none;
    flex: 0;
  }
}
