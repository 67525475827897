.inteach-drawer {
  padding-top: env(safe-area-inset-top);
  padding-top: constant(safe-area-inset-top);
  position: fixed;
  width: 300px;
  top: 0;
  bottom: 0;
  z-index: 9;
  transition: 300ms transform ease-out;
  background-color: #fff;
  transform: translate3d(-100%, 0, 0);

  &__open {
    transform: translate3d(0, 0, 0);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
  }
}

.inteach-drawer__button {
  height: 20px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 0.5rem;

  span {
    height: 3px;
    width: 25px;
    border-radius: 5px;
    background-color: white;

    &:last-child {
      width: 12.5px;
    }
  }
}

@media (min-width: 841px) {
  .inteach-drawer,
  .inteach-drawer__button {
    width: 0;
    display: none;
  }
}

ion-button {
  --color: var(--main-color);
  --border-color: var(--main-color);
}