[class*="Section__container"] {
  width: 50%;
  margin: 0 auto;
  background-color: #fff;

  @media screen and (min-width: 1280px) {
    width: 35%;
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
    background-color: transparent;
  }
}

.amplify-form-input {
  width: 100%;
  margin-bottom: 10px;

  input {
    background-color: rgb(248, 250, 251);
    width: 100%;
    border-radius: 25px;
    height: 40px;
    border: none;
    outline: none;
    padding-left: 20px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
  }
}

.auth-text {
  text-align: center;
  margin: 10px 10px 20px 10px;
}

@media screen and (max-width: 1024px) {
  .registerBtn {
    --color: #fff !important;
    --border-color: #fff !important;
  }
}

.loader-container {
  width: 30%;
}

.login-footer-btn {
  display: flex;
  flex-direction: column;
}
