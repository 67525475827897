@import "../../assets/colors.scss";
@import "../../assets/utils.scss";

.training-item__info {
  display: flex;
  color: $blue-600;
  font-weight: 700;
  font-size: 0.75rem;

  svg {
    margin-right: 5px;
    fill: $gray-600;
  }

  &--xl {
    font-size: 0.9rem;

    svg {
      margin-right: 10px;
    }
  }
  &--available {
    margin-bottom: 2px;
  }
}

.inteach-training-item {
  @include for-size(phone-only) {
    flex-direction: column;
  }

  &--disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.inteach-training-item__image {
  height: 150px;
  flex: 1 1 60%;

  img {
    object-fit: cover;
    height: 150px;
    width: 100%;
  }
}

.inteach-training-item__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  flex: 1 1 40%;
}

.inteach-training-item__title {
  color: $blue-600;
  font-size: 1.125rem;
  text-align: center;

  @include for-size(phone-only) {
    text-align: left;
  }
  &--disabled {
    display: flex;
    flex-direction: column;
  }
}

.inteach-training-item__info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inteach-training-item__info {
  margin-top: 10px;
  display: flex;
  color: var(--grey);

  svg {
    fill: var(--grey);
  }
}

// Training single
.training-single {
  height: 100%;
  position: relative;
}

.inteach-card.training-single__card {
  box-shadow: none;
  border-radius: 0;
  overflow: visible;
}

.training-single__card {
  position: relative;
}

.training-single__image {
  width: 100%;
  height: 250px;
  background: #fff;

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}

.training-single__info-container {
  position: absolute;
  left: 12.5%;
  border-radius: 4px;
  background: #fff;
  width: 75%;
  z-index: 1;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15);

  @include for-size(phone-only) {
    width: 90%;
    left: 5%;
  }
}

.training-single__title {
  background-color: #fff;
  color: $blue-600;
  font-size: 1.6rem;

  @include for-size(phone-only) {
    font-size: 1.33rem;
  }
}

.training-single__actions {
  z-index: 1;
  padding: 20px;
}

.learning-set {
  border-radius: 8px;
}

.learning-set__title {
  color: $gray-600;
}

.learning-set__title,
.learning-item {
  border-bottom: 1px solid $gray-400;
}

.learning-item {
  
  height: 70px;
  border-top: 1px solid var(--light-grey);
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: $gray-200;
    transition: background-color 250ms ease-in-out;
  }

  &--disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.learning-item__check {
  display: flex;
  align-items: center;
}

.learning-item__check-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: $gray-400;
  align-items: center;
  display: flex;
  justify-content: center;

  &--success {
    background-color: var(--main-color);
  }
}

.learning-item__infos {
  display: flex;
  align-items: center;
}

.learning-item__type,
.learning-item__points,
.learning-item__available {
  color: $gray-600;
  font-size: 0.9rem;
}

.learning-item__available {
  font-weight: bold;
  margin-left: 10px;
}

.learning-item__points {
  margin-left: 0.5rem;

  svg {
    margin-right: 5px;
  }
}

.learning-item__title {
  color: $blue-600;
  font-weight: 500;
  margin-bottom: 3px;
  font-size: 1rem;
}

.learning-item__content {
  flex-grow: 1;
  align-self: center;
}

.learning-item__button {
  display: flex;
  align-self: center;
  justify-content: center;

  button {
    background: none;
    cursor: inherit;
    border: none;
    display: flex;
    align-self: center;
    justify-content: center;
  }
}

.inteach-training-single {
  &__learning-set {
    border-radius: 8px;
    background-color: white;
    margin-bottom: 10px;
    &__title {
      color: var(--principal-text);
      padding: 10px 15px;
    }
  }
}
