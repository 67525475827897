.inteach-learning-item-search {
  display: flex;
  padding: 0 !important;
  height: 100px;
  cursor: pointer;

  &__picture {
    flex: 25%;
    width: 25%;
    overflow: hidden;
    text-align: center;
    justify-content: center;
    display: flex;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__infos {
    flex: 75%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.9rem;
  }

  &__title {
    font-weight: bold;
  }
}