.inteach-rightbar {
  flex: 1;

  &__content {
    padding: 20px;
  }

  &__channel-list {
    overflow: scroll;
    max-height: 40vh;
    margin: 1.3rem 0;
    background-color: white !important;
  }

  &__training-time,
  &__sessions-count {
    margin: 20px 0;

    h4 {
      color: var(--principal-text);
      font-weight: normal;
    }

    span {
      display: flex;
      align-items: center;
      color: #1b2749;
      margin-top: 10px;
      margin-bottom: 10px;

      svg {
        margin-right: 8px;
        fill: var(--principal-text);
      }
    }
  }
}

.str-chat {
  height: 100% !important;

  &__list {
    padding-left: 2vw !important;
    padding-right: 2vw !important;
  }
}

@media (max-width: 840px) {
  .inteach-rightbar {
    display: none;
    flex: 0;
  }
}
