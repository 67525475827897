.inteach-profile-picture {
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    cursor: inherit;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__add {
    cursor: inherit;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed grey;
    border-radius: inherit;
  }
}