.inteach-notifications {
  position: relative;
  height: 80px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-grey);
  margin-bottom: 1rem;

  &__title {
    flex: 70%;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__time {
    flex: 30%;
    text-align: right;
    font-size: 12px;
  }

  &__content {
    position: relative;
    display: flex;
    height: 2rem;
    flex: 100%;
    font-size: smaller;
    color: var(--principal-text);
    padding-right: 20px;
    align-items: center;
    margin: 0.5rem 0;
  }

  &--unread &__content {
    font-weight: bold;
    color: black;
  }

  &--unread &__dot {
    display: block;
  }

  &__dot {
    display: none;
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    border-radius: 100px;
    height: 10px;
    width: 10px;
    background-color: var(--primary);
  }
}