.inteach-tabbar {
  height: 70px;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  border-top: 1px solid #dedede;
  background-color: white;

  nav {
    height: 100%;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
  }

  li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  svg {
    color: rgb(58, 58, 58);
    height: 2.4rem;
    width: 2.4rem;
  }

  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &.active {
      border-top: 5px solid var(--main-color);

      svg {
        color: var(--main-color);
      }
    }
  }
}

@media (min-width: 841px) {
  .inteach-tabbar {
    display: none;
  }
}
