:root {
  --secondary: #70747c;
  --lightgrey: #c3cbd0;
  --grey: #7f8592;
  --darkgrey: #55575f;
  --topbar-first: #46eeaa;
  --topbar-second: #2cbfc7;
  --content: #f9f9f9;
  --navbar: #484848;
}
@import "fonts";
@import "utils";
@import "colors";
@import "animations";
@import "~react-circular-progressbar/dist/styles.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "~stream-chat-react/dist/css/index.css";

@import "styles/getStream/getStream";
@import "styles/posts/posts";

// Modals
@import "styles/modals/modals";

// Elements
@import "styles/elements/buttons";
@import "styles/elements/cards";
@import "styles/elements/inputs";
@import "styles/elements/tags";
@import "styles/elements/text";

// React slick
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// React toastify
@import "styles/toasts/toasts";

html,
body,
#root,
.App {
  height: calc(var(--vh, 1vh) * 100);
}

#root {
  background-color: #f9f8f8;
}

body,
html,
h1,
h2,
h3,
h4,
p {
  margin: 0;
}

html,
body,
input {
  font-family: SFPro, serif;
  font-weight: 300;
  font-size: 15px;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

h2 {
  color: var(--secondary);
  margin-bottom: 20px;
}

hr {
  border: none;
  height: 1px;
  background-color: var(--light-grey);
}

.App {
  overflow: hidden;
  transition: 300ms transform ease-out;
}

.inteach-container {
  display: flex;
  position: relative;
  height: calc(100% - 60px);
  background-color: #fff;

  &--center {
    align-items: center;
    justify-content: center;
  }

  &--transparent {
    background-color: transparent;
  }
}

@media (max-width: 840px) {
  .inteach-container {
    height: calc(100vh - 80px);
  }
}

@supports (-webkit-touch-callout: none)  {
  @media (max-width: 840px) {
    .inteach-container {
      height: calc(100vh - (80px + 26px));
    }
  }
}

.inteach-onboarding {
  &__slider {
    width: 100%;
    height: 100%;
  }

  &__slide {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 4rem;
    padding-right: 4rem;

    h3 {
      margin-bottom: 1rem;
      color: inherit;
      font-size: 1rem;
    }

    p {
      color: inherit;
      font-size: 0.9rem;
    }
  }

  @media (max-width: 640px) {
    &__slide {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &__btn {
    text-align: right;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    cursor: pointer;
    color: white;
  }
}

/* Slider */

.slick-slider,
.slick-list,
.slick-track,
.slick-slide > div {
  height: 100% !important;
}

.slick-dots {
  bottom: 2rem;

  li {
    pointer-events: none;

    button {
      pointer-events: none;
    }
  }
}

@media (max-width: 840px) {
  .onesignal-bell-container {
    display: none !important;
  }
}

.infinite-scroll-component {
  overflow: initial !important;
}

.slick-dots li button:before {
  color: #fff;
}

.slick-dots li.slick-active button:before {
  color: #fff;
}

@media (max-width: 1024px) {
  #loginBackground {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    right: 0;
    background: url("../images/login-background.jpg") no-repeat center center fixed;
    background-size: cover;
  }

  .amplify-section-header {
    text-align: center;
    padding: 20px;
  }
  .amplify-section-header-content {
    color: #fff !important;
  }
  .forgot-password-text {
    color: #fff !important;
  }
  .amplify-a {
    color: #fff !important;
  }
}

@supports (-webkit-touch-callout: none)  {
  @media (max-width: 840px) {
    .inteach-chat-header {
      padding-top: env(safe-area-inset-top);
      padding-top: constant(safe-area-inset-top);
    }
  }
}

.inteach-chat-component__chat {
  width: 100%;
  margin:0;
  display: block;
}

.inteach-modal__close {
  align-self: flex-end;
}