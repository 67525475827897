@import "../../../assets/colors.scss";

.inteach-content {
  flex: 2;
  background-color: $gray-200;
  overflow-y: scroll;
  overflow-x: hidden;
}

.inteach-content__content {
  padding: 20px;
  height: 100%;
}

.inteach-content__title {
  font-weight: bold;
  margin-bottom: 1rem;
  color: $blue-600;
}

@media (max-width: 840px) {
  .inteach-content {
    flex: 1;
    margin-bottom: 50px;
  }

  .inteach-content__title {
    display: none;
  }
}
