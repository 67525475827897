@import "../../assets/colors.scss";

.inteach__input {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  & + .inteach__input {
    margin-top: 1rem;
  }
}

.inteach__input-icon-container {
  position: absolute;
  left: 1rem;
}

.inteach__input-icon {
  display: block;
}

.inteach__input-input {
  height: 2.5rem;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: $gray-400;
  padding: 0 15px;
}
