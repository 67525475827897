@import "../../../assets/utils.scss";

.inteach-header {
  padding: 20px;
  @supports (-webkit-touch-callout: none) {
    padding-top: env(safe-area-inset-top);
  }
  align-items: start;
  flex-direction: column;
  justify-content: space-between;

  @include for-size(tablet-portrait-up) {
    padding: 0 100px;
  }

  @include for-size(tablet-portrait-up) {
    width: 100vw;
    background: linear-gradient(
      45deg,
      var(--header-gradient-left-color),
      var(--header-gradient-right-color)
    );
    box-sizing: border-box;
    padding: 0 25px;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 60px;
  }
}

.inteach-header__title {
  color: #fff;
  @include for-size(tablet-portrait-up) {
    display: none;
  }
}

.inteach-header__logo {
  display: none;

  @include for-size(tablet-portrait-up) {
    display: block;

    img {
      height: 30px;
    }
  }
}

.inteach-header__search {
  display: none;
  @include for-size(tablet-portrait-up) {
    display: block;
  }
}

.inteach-header__search__input {
  padding: 0 20px;
  margin-left: 1rem;
  width: 300px;
  border-radius: 40px;
  height: 40px;
  border: none;
}

.inteach-header__notification-btn {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  cursor: pointer;
}
